<template>
    <b-overlay :show="isLoadingData || isSavingHotelPerformance">
        <b-card>
            <div class="head-btn">
                <b-button
                    :to="{ name: 'hotel-performance'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                > <feather-icon icon="ArrowLeftIcon" style="color: white" />
                </b-button>
            </div>
            <ValidationObserver ref="createHotelPerformance" v-slot="{ invalid }">
                <b-form @submit.prevent="save">
                    <b-row>
                        <b-col>
                            <b-row>
                                <b-col md="">
                                    <ValidationProvider name="resort" rules="required">
                                        <b-form-group label="Resort" slot-scope="{ valid, errors }">
                                            <b-form-select
                                                :state="errors[0] ? false : valid ? true : null"
                                                v-model="hotelPerformanceData.idHotel"
                                            >
                                                <option :value="null">Seleccione Resort</option>
                                                <option
                                                    v-for="hotel in hotels"
                                                    :key="hotel.id"
                                                    :value="hotel.id"
                                                > {{ hotel.name }}
                                                </option>
                                            </b-form-select>
                                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="">
                                    <ValidationProvider name="idioma" rules="required">
                                        <b-form-group label="Idioma*" slot-scope="{ valid, errors }">
                                            <b-form-select
                                                :state="errors[0] ? false : valid ? true : null"
                                                v-model="hotelPerformanceData.language"
                                            >
                                                <option :value="null" disabled>Seleccione Idioma</option>
                                                <option v-for="lang in languages" :key="lang.id" :value="lang.id">
                                                    {{ lang.name }}
                                                </option>
                                            </b-form-select>
                                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider rules="required" name="imagenn">
                                        <b-form-group
                                            label="Hotel Performance"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <b-form-file
                                                v-model="hotelPerformanceData.file"
                                                @input="setImgFile(hotelPerformanceData.file)"
                                                placeholder="Adjunte una imagen como evidencia..."
                                                drop-placeholder="Drop file here..."
                                                :state="errors[0] ? false : valid ? true : null"
                                                browse-text="Buscar imagen"
                                            />
                                            <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col class="label-text" md="12">
                                    <validation-provider name="enlace destino" rules="">
                                        <b-form-group slot-scope="{ valid, errors }">
                                            <label>Enlace destino</label>
                                            <b-form-input
                                                v-model="hotelPerformanceData.link"
                                                :state="errors[0] ? false : valid ? true : null"
                                                placeholder="https://"
                                            />
                                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="">
                                    <div class="mt-1">
                                        <b-form-checkbox
                                            v-model="hotelPerformanceData.status"
                                            :value="true"
                                            :unchecked-value="false"
                                            class="custom-control-success"
                                        > Activado
                                        </b-form-checkbox>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col>
                            <b-img
                                :src="sourceFile"
                                class="mb-1"
                                height="225rem"
                                @error="imgAlternativo"
                            />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <div class="float-right">
                                <b-button
                                    type="submit"
                                    class="btn-block"
                                    variant="primary"
                                    :disabled="invalid"
                                > Guardar
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
        </b-card>
    </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { showAlertMessage, getLocalFilePath } from '@/helpers/helpers';
import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple, },
    async created(){
        if( !this.hotels.length || !this.languages.length ){
            await this.init()
        }
    },
    data() {
        return {
            imgDefault: require("@/assets/images/default.jpg"),
            hotelPerformanceData: {
                idHotel: null,
                file: null,
                link: null,
                status: true,
                language: null,
            },
            isSavingHotelPerformance: false,
            isLoadingData: false,
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('start', ['hotels','languages']),
        sourceFile(){
            if (this.hotelPerformanceData?.file) return getLocalFilePath(this.hotelPerformanceData?.file) 
            else return '#'
        },
    },
    methods: {
        ...mapMutations('fivesClubCatalogs', ['setNewsLetters']),
        ...mapActions('fivesClubCatalogs', ['getInitialContentForHotelPerformance', 'saveHotelPerformance']),
        ...mapMutations('start',['setHotels', 'setLanguages']),
        async init(){
            this.isLoadingData = true
            const { hotels, languages } = await this.getInitialContentForHotelPerformance()

            if( !this.hotels.length){
                this.setHotels(hotels)
            }

            if( !this.languages.length ){
                this.setLanguages(languages)
            }

            this.isLoadingData = false
        },
        setImgFile(file) {
            if (file) {
                this.hotelPerformanceData.file = file;
            }
        },
        async save() {
            this.isSavingHotelPerformance = true;
            const payload = {
                ...this.hotelPerformanceData,
                idUser: this.user.idUser,
            }

            const { status, message } = await this.saveHotelPerformance(payload);  // saving in backend

            if (status) {
                showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
                Object.assign(this.$data, this.$options.data());
                this.$refs.createHotelPerformance.reset(); //reset form
			    this.$router.push({ name: 'hotel-performance' })
            }

            this.isSavingHotelPerformance = false
        },
        imgAlternativo(event) {
            event.target.src = this.imgDefault
        },
    },
};
</script>

<style>
.head-btn{
    display: flex;
    justify-content: flex-end;
}
</style>
